.card-info,
.timeline-item,
.step-number,
.reviews-item {
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.card-item .img-wrapper {
    max-height: 375px;
    overflow: hidden;
}

.card-item .secondary-title {
    @apply mb-4;
}

.card-item {
    transform: translateY(50px);
    opacity: 0;
}

.step-number {
    width: 50px;
    height: 50px;
}

@media screen and (min-width: 768px){
    .step-number {
        width: 80px;
        height: 80px;
    }
}

.timeline-wrapper {
    @apply justify-between pb-12 md:pb-24;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.timeline-wrapper:nth-child(even) {
    @apply md:flex-row-reverse;
}

.groups-card {
    @apply flex-shrink-0 bg-white hover:bg-light-grey cursor-pointer h-12 flex justify-center items-center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    transition: .1s;
}

.groups-card-title {
    @apply text-base md:text-xl tracking-wider;
}

.groups-button {
    height: 77px;
}

.groups-card-lightbox-title {
    @apply text-2xl font-bold mb-6;
}

.groups-card-description {
    @apply text-xl;
}

.prestation-item {
    @apply w-full md:w-groups-card shadow-mainShadow rounded-mainRadius h-prestation-item flex flex-col gap-8 items-center justify-center;
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.prestation-item ul {
    @apply text-sm md:text-base;
}

.prestation-item:hover .prestation-icon {
    transform: translateY(-5px);
}

.prestation-icon {
    transition: .2s ease-out;
}

.prestation-icon img {
    width: 85px;
    height: auto;
    transition: .1s ease-out;
}

.img-card {
    max-width: 150px;
}

.groups-wrapper::-webkit-scrollbar {
    background: transparent;
}

.groups-card-lightbox-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.482);
    z-index: 50;
    @apply items-center justify-center cursor-default;
}

.groups-lightbox-close-overlay {
    background: rgba(0, 0, 0, 0.15);
}

.groups-card-lightbox {
    @apply bg-white relative rounded-mainRadius h-screen md:h-high-layout w-full px-4 lg:px-8 py-8 max-w-desktop-layout shadow-mainShadow;
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.groups-card.active .groups-card-lightbox-wrapper {
    display: flex;
}

.devis-call-to-action {
    @apply uppercase mx-auto cursor-pointer flex justify-center items-center text-base shadow-mainShadow bg-white w-groups-card h-16 rounded-mainRadius hover:text-green;
    border: 1px solid rgba(128, 128, 128, 0.25);
    transition: .1s ease-out;
}

.devis-call-to-action:hover {
    border: 2px solid #99C355;
}

.bref-content {
    @apply flex flex-col gap-4 md:flex-row;
}

.bref-item {
    @apply w-full bg-white flex justify-center items-center rounded-mainRadius shadow-mainShadow;
    border-top: 1px solid rgba(128, 128, 128, 0.25);
    height: 253px;
}

.bref-item-title {
    @apply text-xl md:text-2xl font-bold;
}

.bref-centre {
    @apply text-xl lg:text-2xl font-bold flex flex-col gap-8;
}

.common-list {
    @apply text-base md:text-xl flex flex-col gap-6 pl-6;
}

.list-arrow {
    @apply relative;
}

.list-arrow::before {
    content: '➔';
    @apply absolute -left-6 top-0;
}