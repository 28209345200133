.nav-link {
    @apply text-sm uppercase;
}

.nav-link.secondary {
    @apply text-xs;
}

.nav-wrapper {
    @apply px-6;
}

.burger-menu {
    width: 45px;
    height: 30px;
    @apply flex-col justify-between;
  }
  
.burger-menu div {
    height: 4px;
    width: 100%;
    background: black;
}

.secondary-links {
    @apply flex justify-center gap-8;
    border-top:1px solid rgba(128, 128, 128, 0.25)
}

.nav-mobile {
    @apply fixed top-0 right-0 h-full translate-x-full bg-white w-sm lg:hidden z-40 pl-8 pt-16 flex;
}

.nav-mobile ul {
    @apply flex flex-col gap-8;
}

.nav-mobile .nav-link {
    @apply font-bold tracking-wide;
}

.secondary-links .nav-link {
    @apply text-xs font-normal;
}

.nav-overlay {
    @apply fixed top-0 right-0 bottom-0 left-0 translate-x-full z-20 md:hidden;
}

@media screen and (min-width: 1024px){
    .nav-wrapper {
        border: 1px solid rgba(128, 128, 128, 0.25);
    }
}