.prestation-link {
    width: 80%;
    left: 50%;
    transform:translateX(-50%);
    transition: .1s ease-in-out;
}

/* .service-item {
    flex-basis: 33.33333%
} */

.service-item .img-wrapper {
    @apply h-cardServiceMobile lg:h-cardService;
    background-size: cover;
    background-position: center;
}

.service-item:hover .prestation-link {
    width: 85%;
}