.footer {
    border-top: 1px solid rgba(128, 128, 128, 0.25);
}

.footer-secondary-title {
    @apply font-bold text-base lg:text-xl mb-3 tracking-wider uppercase;
}

.footer-text {
    @apply text-sm;
}

.footer-third-title {
    @apply text-xs mb-2 opacity-75 italic;
}

.footer a {
    @apply hover:text-green;
}

.footer-links ul {
    border-top: 1px solid rgba(128, 128, 128, 0.25);
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
}