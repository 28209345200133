.App {
  min-height: 100vh;
  font-family: var(--main-font);
}

/* nav {
  background: green;
} */

/* .nav-overlay {
  background-color: yellow;
} */

/* GLOBALS CSS HERE */

.layout {
  @apply px-4 mx-auto md:max-w-desktop-layout;
}

.with-pt {
  @apply pt-2 md:pt-16 lg:pt-32;
}

.layout-smaller {
  @apply px-4 mx-auto md:pt-16 md:max-w-desktop-layout-smaller;
}

.main-title {
  @apply font-bold text-4xl mb-4 lg:mb-0 lg:text-6xl text-green;
}

.main-title-normal {
  @apply text-xl mb-6 md:mb-10 font-bold md:text-6xl;
}

.secondary-title {
  @apply font-bold text-2xl lg:text-4xl mb-4 lg:mb-6;
}

.call-to-action-title {
  @apply font-bold text-base lg:text-4xl mb-4 lg:mb-12;
}

.special-title {
  @apply font-bold text-2xl lg:text-6xl mb-6 lg:mb-16;
}

.third-title {
  @apply font-bold text-2xl lg:text-3xl mb-2 lg:mb-6;
}

.special-text {
  @apply text-base md:text-2xl;
}

.common-text {
  @apply text-base md:text-xl leading-6 lg:leading-8 mb-4 lg:mb-6;
}

.container-layout {
  @apply py-16 lg:py-36;
}

.with-mb {
  @apply mb-20 lg:mb-32;
}

.bigger-mb {
  @apply mb-24 lg:mb-80;
}

.page-section {
  @apply h-high-layout flex flex-col justify-center;
}

.reveal-element {
  /* clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%); */
}

/* .video-container {
  animation: appear 1s cubic-bezier(.62,0,.37,1);
} */

.link {
  @apply hover:text-green;
  transition: .1s ease-out;
}

.step {
  opacity: 0;
  transform: translateY(25px);
}

/* @keyframes appear {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
} */