.theme-light {
    --brand-primary: rgb(47, 112, 193);
    --brand-secondary: rgb(116, 97, 195);
    --brand-alternative: rgb(19, 120, 134);
    --background-site: rgb(249, 249, 249);
    --background-code: rgb(244, 244, 244);
    --text-body: rgb(54, 49, 61);
  }

.embla {
--slide-spacing: 1rem;
--slide-size: 100%;
--slide-height: 35rem;
}

.gallery-carousel {
    position: relative;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    flex-direction: row;
    height: auto;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    overflow: hidden;
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
}

.embla__slide__img {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
}

.embla__dot,
.embla__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
}

.embla__dots {
    z-index: 1;
    bottom: 1.2rem;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.embla__dot {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
}

.embla__dot:after {
    background: white;
    border-radius: 0.2rem;
    width: 100%;
    height: .3rem;
    content: '';
    border: 1px solid rgba(128, 128, 128, 0.5);
    @apply shadow-mainShadow;
}

.embla__dot--selected:after {
    background: rgba(255, 255, 255, 0.2);
    border: none;
}

.embla__button {
    z-index: 1;
    color: var(--background-site);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 4rem;
    height: 4rem;
}

.embla__button--prev {
    left: 1.6rem;
}

.embla__button--next {
    right: 1.6rem;
}

.embla__button:disabled {
    opacity: 0.3;
}

.embla__button__svg {
    width: 65%;
    height: 65%;
    filter: drop-shadow(0 3px 15px rgb(0 0 0 / .5));
}