.conseil-fondation-item {
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.title-about {
    @apply font-bold text-4xl mb-8 lg:mb-12 lg:text-6xl text-green;
}

.historique-item {
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.contact-map {
    border: 1px solid rgba(128, 128, 128, 0.25);
}

.lien-item {
    height: 64px;
    /* border-top: 1px solid rgba(128, 128, 128, 0.25); */
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    @apply flex justify-between items-center md:px-8;
}

.rapport-container {
    @apply gap-4;
}

.rapport-item {
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    height: 64px;
    @apply hover:text-green flex justify-between items-center md:px-8;
}

.rapport-item img {
    @apply opacity-75;
}