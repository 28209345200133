@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@font-face {
  font-family: 'Avenir Next', sans-serif;
  src: url(./styles/font/AvenirNextLTPro-Bold.woff) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next', sans-serif;
  src: url(./styles/font/AvenirNextLTPro-Regular.woff) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@import 'styles/nav.css';
@import 'styles/home.css';
@import 'styles/footer.css';
@import 'styles/page.css';
@import 'styles/about.css';
@import 'styles/carousel.css';

:root {
  --main-font: 'Avenir Next', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-white text-dark-color;
  /* font-size: 1vmin; */
}

::selection{
  @apply bg-green text-white;
}